import APIEndpoints from '../constants/api-endpoints.json';
import FAQmeta from '../constants/faq-meta.json';
import { CROSS_PLATFORM, OFFICIAL_PLATFORM_LIST, OFFICIAL_PLATFORM_MAP } from '../../config/platforms';
import { navigate } from 'gatsby';
import { DEFAULT_PLATFORMS } from '../../config/languages';
import { NO_REDIRECT_ROUTES, PROD_FIRST_CORE_LANGUAGE_PLATFORMS, PROD_FIRST_CORE_PLATFORMS, NON_PROD_FIRST_FRAMEWORKS } from '../../config/platforms';
import { simpleMemoizeFunction } from './Memo';

const { WEB, CLI, CORE } = OFFICIAL_PLATFORM_MAP;

const IS_SERVER_RENDER = typeof window === 'undefined';

export const getBlogURLFromNode = (node) => {
  const { id, frontmatter } = node;

  if (frontmatter && frontmatter.url) return frontmatter.url;

  let url = id.split('.md')[0].replace(/\./g, '-');
  url = '/blog' + url.split('/blog')[1];

  const hub = url.split('/')[2];
  const article = url.split('/')[3];

  return url;
};

export const formatStringForId = (s) => {
  s = s.replace(/\*/g, '');
  s = s.replace(/[\(\)]/g, '');
  s = s.replace(/<(.*?)>/g, '');
  s = s.replace(/\[(.*?)\]/g, '');
  s = s.replace(/'|"|’/g, '');
  // eslint-disable-next-line
  s = s.replace(/\?|\!/g, '');
  s = s.replace(/-|\(|\)|\.|,/g, '');
  s = s.replace(/\//g, ' ');
  s = s.replace(/\s{2,}/g, ' ');
  s = s.replace(/%/g, ' ');
  s = s.trim().split(' ').join('-');

  return s.toLowerCase();
};

export const formatForTOC = (s) => {
  s = s.replace(/\([\s\S]*?(\)|$)/g, '');
  return s;
}

export const setParam = (key, value) => {
  if (!IS_SERVER_RENDER && window.history) {
    const query = getQueryString(window.location, key, value);
    var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + query ;
    window.history.pushState({path:newurl},'',newurl);
  }
}

export const setPlatformAndLanguageParam = (value) => {
  if(PROD_FIRST_CORE_PLATFORMS.includes(value)) {
    setParam('platform', value);
  } else if(PROD_FIRST_CORE_LANGUAGE_PLATFORMS.includes(value)) {
    setParam('language', value);
  }
}

export const parseHashParameter = (location) => {
  const { hash } = location;

  return hash.split('?')[0].replace('#', '');
}

export const parseHash = (location) => {
  if (!location || location.hash === undefined) return {};
  let hash = location.hash.substring(1);
  let queries = hash.split('&');
  const map = {};

  queries.forEach((q) => {
    const [key, value] = q.split('=');
    map[key] = value.split(',');
  });

  return map;
}

export const queryToObject = (location) => {

  if (!location || !location.search) return {};



  var search = location.search.substring(1);

  if(search.endsWith('/')) {
    search = search.slice(0, -1);
  }

  if (search === '') {
    const hash = location.hash;
    if (hash && hash.indexOf('?') > -1) {
      search = hash.split('?')[1];
    }
  }

  if (!search) return {};

  const queries = search.split('&');

  const map = {};

  queries.forEach((q) => {
    const [key, value] = q.split('=');
    map[key] = value.split(',');
  });

  return map;
};

export const getPathInfo = (pathname, location) => { 

  if (!pathname) return {};

  let formattedPathName = pathname;
  if (formattedPathName.startsWith('/')) {
    formattedPathName = formattedPathName.substr(1);
  }

  if (formattedPathName.endsWith('/')) {
    formattedPathName = formattedPathName.slice(0, -1);
  }

  const split = formattedPathName.split('/');

  const rootPath = split[0] || null;
  let platform = split[1] || null;
  let section = split[2] || null;
  const guidePath = split.slice(2).join('/');

  if (platform === 'samples') {
    const o = location ? queryToObject(location) : {};
    section = 'samples';
    if (o.platforms) {
      platform = o.platforms[0];
    } else {
      if (split.length === 4) {
        platform =  DEFAULT_PLATFORMS[split[2]];
      } else if (split.length === 5) {
        platform = DEFAULT_PLATFORMS[split[2] + '.' + split[3]];
      } else {
        platform = '';
      }
    }
  }

  if (formattedPathName === 'webviewer') {
    platform = 'web';
  }

  if (platform === 'guides') {
    platform = CROSS_PLATFORM;
  }

  if (OFFICIAL_PLATFORM_LIST.indexOf(platform) === -1) {
    platform = null;
  }

  if (section === 'get-started') section = 'guides';

  return {
    rootPath,
    platform,
    section,
    guidePath
  };
};

export const getRelatedRoute = (relatedRoutes, fromRoute, toPlatform) => {

  if (!fromRoute.startsWith('/')) {
    fromRoute = '/' + fromRoute;
  }

  if (fromRoute.endsWith('/')) {
    fromRoute = fromRoute.slice(0, -1);
  }

  const data = relatedRoutes[fromRoute];
  if (data) {
    const toGo = data[toPlatform];
    if (toGo) {
      return toGo;
    }
  }

  let reverseSearch = null;

  Object.keys(relatedRoutes).forEach((key) => {
    if (key === 'root' || reverseSearch) return;

    const childKeys = Object.keys(relatedRoutes[key]);
    const childValues = Object.values(relatedRoutes[key]);
    const { platform: keyPlatform } = getPathInfo(key);

    const hasMatch = childValues.some(v => v === fromRoute);

    if (hasMatch) {
      if (childKeys.indexOf(toPlatform) > -1) {
        reverseSearch = relatedRoutes[key][toPlatform];
        return;
      }

      if (keyPlatform === toPlatform) {
        reverseSearch = key;
        return;
      }
    }
  });

  if (reverseSearch) {
    return reverseSearch;
  }

  const from = getPathInfo(fromRoute);

  if (from.section === 'faq' && FAQmeta.indexOf(toPlatform) > -1) {

    return `/documentation/${toPlatform}/faq`;
  }

  return relatedRoutes.root[toPlatform];
};

export const isDocPage = (pathname) => {
  if(/^\/documentation\/mobile\/?\S?/.test(pathname) ||  /^\/documentation\/support\/?\S?/.test(pathname) ||
  /^\/documentation\/desktop\/?\S?/.test(pathname) || 
  /^\/documentation\/web-platform\/?\S?/.test(pathname) || 
  /^\/documentation\/server\/?\S?/.test(pathname)) {
    return false
  } else if(/^\/documentation\/samples\/?\S?/.test(pathname) || /^\/documentation\/\S+/.test(pathname)) {
    return true
  }
};

export const isCoreGetStartedPage = (pathname) => {
  if(!pathname) return false;
  const GET_STARTED_ROUTE = "/documentation/core/guides/get-started/";
  return pathname.includes(GET_STARTED_ROUTE);
}

export const isAllSamplesPage = (location) => /^\/documentation\/samples(\/?)$/.test(location.pathname) && !location.search;

export const shouldShowLicenseModal = (pathname) => /^\/form\/sign-in/.test(pathname);

export const isNewPath = (oldLocation, nextLocation) => {
  return oldLocation.pathname !== nextLocation.pathname ||
    oldLocation.search !== nextLocation.search ||
    oldLocation.hash !== nextLocation.hash
}

export const formatLink = l => {
  if (!l.startsWith('/')) {
    l = '/' + l;
  }

  if (!l.endsWith('/')) {
    l += '/';
  }
  return l;
}

export const formatLinkNoTrialingSlash = (l) => {
  if (!l.startsWith('/')) {
    l = '/' + l;
  }

  if (l.endsWith('/')) {
    l = l.slice(0, -1);
  }

  return l;
}

export const urlsMatch = (u1, u2, baseLink) => {

  if (typeof u1 !== 'string' || typeof u2 !== 'string') return false;
  u1 = u1.trim();
  u2 = u2.trim();
  
  u1 = formatLink(u1);
  u2 = formatLink(u2);

  if (baseLink) {
    baseLink = formatLink(baseLink.trim());
    if (baseLink === u1) return true;
    if (baseLink === u2) return true;
  }
  
  return u1 === u2;
}

export const setLastPage = (pathname) => {
  if (typeof window === 'undefined') return;
  localStorage.setItem("@pdftron_last_page", pathname);
}

export const getLastPage = () => {
  if (typeof window === 'undefined') return '';
  return localStorage.getItem("@pdftron_last_page");
}


export const getQueryString = (location, key, value) => {
  if (!location.search) {
    return `?${key}=${value}`;
  }

  const obj = queryToObject(location);
  if (!value) {
    delete obj[key];
  } else {
    obj[key] = value;
  }


  return Object.keys(obj).reduce((acc, key, index) => {
    return acc + `${index === 0 ? '?' : '&'}${key}=${obj[key]}`
  }, '')
}

export const goToPlatform = (relatedRoutes, platform, location, link) => {
  if (link) {
    navigate(addQueryParams(link, platform));
    return;
  }

  const { pathname } = location;
  const pathInfo = getPathInfo(pathname, location);

  // show root page if selecting current platform
  if (pathInfo.platform === platform) {
    return navigate(addQueryParams(relatedRoutes.root[platform]));
  }

  const { section } = pathInfo;

  if (section === 'samples') {
    if (platform === WEB) {
      navigate(`/documentation/web/samples/viewing`);
    } else if (platform === CLI) {
      navigate('/documentation/cli/guides');
    } else if (platform === CORE) {
      navigate('/documentation/samples/');
    } else {
      // window.location.href = ;
      navigate(`/documentation/samples/?platforms=${platform}`);
    }

    return;
  }

  const relatedRoute = getRelatedRoute(relatedRoutes, pathname, platform);

  if(relatedRoute && relatedRoute !== false){
    navigate(addQueryParams(relatedRoute));
  } else {
    navigate(addQueryParams(`/documentation/${platform}`));
  }
}


const endsInFileExtension = (url) => {

  if (url.endsWith('.com')) return false;
  
  const fileExtensionRegex = /\.(svg|pdf|docx|[a-z0-9]+)$/i;
  const hasFileInURL = fileExtensionRegex.test(url);
  return hasFileInURL;
};

const isBlacklisted = (s) => {
  const bl = ['nightly.pdftron.com', 'mailto:', 'support.pdftron.com', '/api', 'en.wikipedia.org'];
  return bl.some(item => s.indexOf(item) > -1);
}

// Utility to ensure URL has a trailing slash
export const ensureTrailingSlash = (url) => {
  if (typeof url !== 'string') return url;

  //don't update if it's a full file path
  if (endsInFileExtension(url)) return url;
  
  return url.endsWith('/') ? url : `${url}/`;
};

export const removeTrailingSlash = (url) => {
  return url.endsWith('/') ? url.slice(0, -1) : url;
};

const LANGUAGE_SPELLING_MAP = {
  "dot-net": "dotnet",
  "dotnet-core": "dotnetcore",
}
export const ensureLanguageSpelling = (language) => {
  return LANGUAGE_SPELLING_MAP[language] || language;
}



// Check if URL should be ignored based on predefined routes
const shouldExcludeFromRedirect = (url) => NO_REDIRECT_ROUTES.some(route => `/${getPathInfo(url).section}/`.includes(route)) && !url.includes('/guides/');

/**
 * Extracts the platform or language segment from a URL, if present.
 *
 * @param {string} url - The URL from which to extract the platform or language.
 * @returns {string|null} - The extracted segment or null if not found.
 */
export const extractPlatformOrLanguage = (url) => {
  url = ensureTrailingSlash(url);

  const urlSegments = url.split('/');
  const platform = urlSegments.find(segment => PROD_FIRST_CORE_PLATFORMS.includes(segment)) || null;
  const language = urlSegments.find(segment => PROD_FIRST_CORE_LANGUAGE_PLATFORMS.includes(segment)) || null;
  return { platform, language };
};

/**
 * Replaces the platform segment in a URL with a new segment, updating the query parameters accordingly.
 *
 * @param {string} url - The original URL.
 * @param {string} platform - The platform segment to replace.
 * @param {string} replacement - The replacement segment for the platform.
 * @param {URLSearchParams} queryParams - The URL search parameters to update.
 * @returns {string} - The URL with the platform replaced.
 */
const replacePlatform = (url, platform, replacement) => {
  url = ensureTrailingSlash(url);
  const platformMatch = new RegExp(`/${platform}/`);
  // Construct the replacement segment string
  const replacementSegment = replacement ? `/${replacement}/` : '/';
  return url.replace(platformMatch, replacementSegment);
};

const stripTrailingPlatforms = (url) => {
  const segments = ensureTrailingSlash(url).split('/');
  const lastSegment = segments[segments.length - 2];
  [...PROD_FIRST_CORE_PLATFORMS, 'core'].forEach(platform => platform === lastSegment ? url = url.replace(`/${lastSegment}/`, '/') : null); 
  return url;
}


const makeUrl = (base, query, anchor) => {
  return `${base}${query ? `?${query}` : ''}${anchor ? `#${anchor}` : ''}`;
}
const edgeCaseFrameworks = [
  '/cordova/',
  '/c-interface-binding/',
  '/catalyst/',
]

const replacePathSegmentForFrameworks = (url) => {
  let baseUrl = url;
  edgeCaseFrameworks.forEach((oldSegment ) => {

  if (baseUrl.includes(oldSegment) && !baseUrl.includes('/get-started/')) {
    baseUrl = baseUrl.replace(oldSegment, '/get-started/');
  }
})
return baseUrl;
}

/**
 * Adds query parameters to a URL based on the provided platform, focusing on documentation pages.
 * It ensures platform specific parameters are added only if relevant.
 *
 * @param {string} urlString - The URL string to which query parameters will be added.
 * @param {string} [platform] - The platform to use for query parameters.
 * @returns {string} - The modified URL with added query parameters.
 */
export const addQueryParams = (urlString) => {
  if (!urlString || typeof urlString !== 'string' || !urlString.includes('/documentation/')) {
    return urlString;
  }

  // Early return if redirects should be ignored based on URL checks
  if (shouldExcludeFromRedirect(urlString)) {
    return urlString;
  }

  
  const queryParams = new URLSearchParams(urlString.split('?')[1] || '');
  // Extract platform from URL if not provided

  const anchorUrl = urlString.split('#')[1];
  let baseUrl = ensureTrailingSlash(urlString.split('?')[0] || urlString);
  baseUrl = ensureTrailingSlash(baseUrl.split('#')[0] || baseUrl);
  const query = queryParams.toString(); 
  const segments = ensureTrailingSlash(baseUrl).split("/");
  const lastSegment = segments[segments.length - 2];

  // Incoming url parsing from url string rather than guide info
  // Because the url string is the most accurate source of truth since we switched to prod-first
  // only need to look at baseURL. 
  let {platform: parsedPlatform, language: parsedLanguage} = extractPlatformOrLanguage(baseUrl);

  // still need to modify the url for the edge case frameworks

  // no core platform or language found in the url
  // return early for urls that don't have core platform or language
  // these could be either non-prod first urls or core urls that have been modified by this function
  if((!parsedPlatform && !parsedLanguage)) {
    return makeUrl(baseUrl, query, anchorUrl);
  }
  if(baseUrl.includes('/trial-key/')) {
    return '/documentation/core/guides/get-started/trial-key/'
  }
  baseUrl = replacePathSegmentForFrameworks(baseUrl);
  
  const platformsLandingPageRegex = new RegExp(`documentation/(${PROD_FIRST_CORE_PLATFORMS.join('|')})/(guides/||info/.*)$`);
  const languagesLandingPageRegex = new RegExp(`documentation/(${PROD_FIRST_CORE_LANGUAGE_PLATFORMS.join('|')})/(guides/||info/.*)$`);
  const infoPageRegex = new RegExp(`/info/`);
  const isRootPlatformPage = platformsLandingPageRegex.test(baseUrl);
  const isRootLanguagePage = languagesLandingPageRegex.test(baseUrl);

  // If the url is equal to exactly /documentation/{platform}/ +/- /guides/ or /info/, redirect to the core guides page + platform=${language}
  if(isRootPlatformPage || isRootLanguagePage) {
    if(isRootPlatformPage) {
      queryParams.delete('platform'); // Remove existing query prod-first parameter
      queryParams.set('platform', parsedPlatform);
    } else {
      queryParams.delete('language'); // Remove existing query prod-first parameter
      queryParams.set('language', parsedLanguage);
    }
    if (infoPageRegex.test(baseUrl)) {
      baseUrl = `/documentation/core/info/`+ baseUrl.split('/info/')[1];
    } else {
      baseUrl = `/documentation/core/guides/`;
    }
    return makeUrl(baseUrl, queryParams, anchorUrl);
  }

  const isGetStartedPage =( baseUrl.includes('/get-started/') || languagesLandingPageRegex.test(baseUrl)) && !baseUrl.includes('/getting-help/');
  const isLastSegmentCorePlatform = PROD_FIRST_CORE_PLATFORMS.includes(lastSegment);
  const partAfterGetStarted = isGetStartedPage ? removeTrailingSlash(baseUrl.split('/get-started/')[1]): '';
  const isPartAfterGetStartedCorePlatform = PROD_FIRST_CORE_PLATFORMS.includes(partAfterGetStarted);

  // remove tabbed pages tab choice from the url
  // example: 
  // - format: /documentation/linux/guides/a-page-with-tabs/platform-tab-choice
  // - in practice: /documentation/linux/guides/annots/linux
  if(isGetStartedPage && isLastSegmentCorePlatform) {
      //handles the case where url is like /documentation/core/guides/get-started/linux/
      // this is a valid url and should not be modified
      // in this case last segment is core, and is after get started so we can assume it is a valid url
      if(isPartAfterGetStartedCorePlatform && baseUrl.includes('core')) {
        return makeUrl(baseUrl, query, anchorUrl);
      }
      baseUrl = replacePlatform(baseUrl, lastSegment, '');
  }

  let hasCorePlatform = parsedPlatform && PROD_FIRST_CORE_PLATFORMS.includes(parsedPlatform);
  let hasCoreLanguage = parsedLanguage && PROD_FIRST_CORE_LANGUAGE_PLATFORMS.includes(parsedLanguage);

// add url param for the platform
// urls already modified by this function will not have the platform or language in the url
// so the following conditions will only run if the url has non parsed out platform or language in the url
// valid core urls will not meet these conditions and will remain unchanged
if(hasCorePlatform) {
  queryParams.delete('platform'); // Remove existing query prod-first parameter
  queryParams.set('platform', parsedPlatform);
  baseUrl = replacePlatform(baseUrl, parsedPlatform, ''); //remove the platform from the url
}

if(hasCoreLanguage) {
  queryParams.delete('language'); // Remove existing query prod-first parameter
  queryParams.set('language', parsedLanguage);
  baseUrl = replacePlatform(baseUrl, parsedLanguage, ''); //remove the language from the url
  if(isGetStartedPage) { // need special handling for get started pages
    queryParams.delete('language'); // Remove existing query prod-first parameter
    if(!baseUrl.includes(`/get-started/${parsedLanguage}`)){
      // need to check if the page is python or python2 or python3
      // example: /documentation/python/guides/get-started/python3 (not real url, but possible)     
        const isPythonGuide = baseUrl.includes('/python');
        if(isPythonGuide) {
          // If the url contains python, we need to check if it is python2 or python3
          const pythonVersion = segments.find(segment => segment.startsWith('python') && segment !== 'python');
          const pythonType = pythonVersion || 'python'; 
          baseUrl = replacePlatform(baseUrl, `${pythonType}`, '');
          baseUrl = `${baseUrl}${pythonType}`;
        } else {
          baseUrl = `${baseUrl}${parsedLanguage}`;
        }
      }
  }
}

  // clean up URL
  // with valid core urls these have no effect since none of these conditions are met
  baseUrl = baseUrl.replace('/integration/', '/'); // remove integration from url
  baseUrl = !baseUrl.includes('/core/') ? baseUrl.replace('/documentation/', '/documentation/core/') : baseUrl; // add core to url if not already there
  baseUrl = !baseUrl.includes('/guides/') ?  baseUrl.replace('/core/', '/core/guides/') : baseUrl; // add guides to url if not already there

  baseUrl = stripTrailingPlatforms(baseUrl); // needed for the get started pages and tabbed pages 
  baseUrl = ensureTrailingSlash(baseUrl); // losing last slash for langauge changes.  double check here.
  const newLastSegment = baseUrl.split('/');
  let newLastSegmentValue = newLastSegment[newLastSegment.length - 2];
  const isLastSegmentLanguage = PROD_FIRST_CORE_LANGUAGE_PLATFORMS.includes(newLastSegmentValue);

  // accounting for pages like /documentation/core/guides/annots/cpp
  if(isLastSegmentLanguage && !isGetStartedPage) {
    baseUrl = replacePlatform(baseUrl, newLastSegmentValue, '');
    queryParams.delete('language'); // Remove existing query prod-first parameter
    queryParams.set('language', newLastSegmentValue);
    newLastSegmentValue = newLastSegment[newLastSegment.length - 3];
  }

  const isLastSegmentTab = ['new-project', 'existing-project'].includes(newLastSegmentValue);
  if(isLastSegmentTab) {
    baseUrl = replacePlatform(baseUrl, newLastSegmentValue, '');
    queryParams.delete('t');
    queryParams.set('t', newLastSegmentValue);
  } 

  // Construct final URL with query parameters
  const queryParamsString = queryParams.toString() ? `?${queryParams.toString()}` : '';
  const anchorUrlString = anchorUrl ? `#${anchorUrl}` : ''; 
  const finalUrl = `${baseUrl}${queryParamsString}${anchorUrlString}`;
  return finalUrl;
};

export const makeConsistentURL = simpleMemoizeFunction((url) => {

  if(url.includes('pdftron.s3.amazonaws.com')) {
    return url;
  }

  if (isBlacklisted(url) || url.startsWith('#')) {
    return url;
  }

  let search = url.indexOf('?');
  let hash = url.indexOf('#');
  const urlIncludesParam = search > -1 || hash > -1;

  // if its an external PDFTron URL thats not blacklisted
  const isSubdomain = url.indexOf('.pdftron.com') > -1;

  if (!endsInFileExtension(url) && !url.endsWith('/') && !urlIncludesParam) {
    url = ensureTrailingSlash(url);
  }

  // links to apryse.com should all be https and should not end in a trailing slash
  if(url.includes('apryse.com') && !url.includes('docs.apryse.com')) {
    url = url.replace('http://', 'https://');
    if(url.endsWith('/')) {
      url = url.slice(0, -1);
    }
  }



  // Internal URLs should always end in a /
  if(url.startsWith('/') && search === -1 && hash === -1) {
    if (!url.endsWith('/')) {
      url = ensureTrailingSlash(url);
    }
  }

  if (url.indexOf('pdftron.com') > -1 && !isSubdomain) {
    url = url.replace('http://', '').replace('https://', '').replace('www.', '');
    url = `https://www.${url}`;
  } else if(url.indexOf('http') > -1 || url.indexOf('www') > -1) return url;


  let append = '';

  if(!(search === -1 && hash === -1)) {
    let use = -1;
    if( search === -1 ) {
      use = hash;
    } else if(hash === -1) {
      use = search;
    } else {
      use = Math.min(hash, search);
    }
    
    append = url.slice(use, url.length);
    url = url.replace(append, '');
  }

  return addQueryParams(`${url}${append}`)
})